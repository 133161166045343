<template>
  <div class="overflow-auto reponse">
    <div class="mx-4">
      <small class="text-muted">
        {{ rParserEtools["response"]["mergedRecords"].length }} résultats
      </small>
      <small class="text-muted">
        ({{ rParserEtools["response"]["durationMs"] }} ms)
      </small>
      <small v-if="mergedRecords[1] > 0" class="text-muted">
        {{ mergedRecords[1] }}
      </small>
      <a
        v-if="mergedRecords[1] > 0"
        v-on:click="setSort"
        class="btn btn-sm btnreset float-end mx-2"
      >
        <i class="bi bi-x"></i>Reset filter
      </a>
    </div>
    <ul class="my-2 px-3">
      <li
        class="result rounded-3 p-3"
        v-for="results in mergedRecords[0]"
        v-bind:key="results['id']"
        v-bind:id="results['id']"
      >
        <a
          v-bind:href="results['url']"
          class="stretched-link text-break"
          rel="noopener noreferrer"
        >
          {{ results["title"] }}
        </a>
        <p class="text-break">{{ results["text"] }}</p>
        <small class="text-muted text-break">{{ results["url"] }}</small>
        <br />
        <span
          v-for="sources in results['sources']"
          v-bind:key="sources"
          class="badge bg-secondary mx-1"
        >
          {{ sources }}
        </span>
        <br />
        <span
          v-for="cluster in getid(results['id'])"
          v-bind:key="cluster + cluster['id']"
          class="badge cluster mx-1 text-break"
        >
          {{ cluster }}
        </span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
li.result:hover {
  color: inherit;
  background-color: rgba(59, 131, 192, 0.05);
  box-shadow: 0 0 0 2px rgba(59, 131, 192, 0.3);
}
li.result {
  position: relative;
}
li {
  list-style: none;
}

.cluster {
  background-color: #22609e;
  color: white;
}
.reponse {
  height: calc(100vh - 120px);
}

.btnreset {
  background-color: #22609e;
  color: white;
  border: 0px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "reponse",
  data: function () {
    return {
      currentTab: "reponse",
      clusters: [],
    };
  },
  computed: {
    ...mapState(["rParserEtools", "reponse_carrot", "sort"]),
    mergedRecords: function () {
      const respon = [];
      if (this.sort["id"] == -1) {
        if (this.sort["list"].length != 0) {
          this.sort["list"].forEach((element) => {
            respon.push(
              this.rParserEtools["response"]["mergedRecords"][element]
            );
          });
          return [respon, this.sort["labels"].length];
        }
      } else {
        return [
          [this.rParserEtools["response"]["mergedRecords"][this.sort["id"]]],
          1,
        ];
      }
      return [this.rParserEtools["response"]["mergedRecords"], 0];
    },
  },
  methods: {
    getid: function (id) {
      const liste_labels = [];
      this.reponse_carrot["clusters"].forEach((cluster) => {
        cluster["documents"].forEach((documents) => {
          if (documents + 1 == id) {
            liste_labels.push(cluster["labels"][0]);
          }
        });
      });
      return liste_labels;
    },
    setSort: function () {
      this.$store.commit("set_sort", {
        list: [],
        labels: [],
        id: -1,
      });
    },
  },
};
</script>
